import { Button, HStack, Skeleton } from '@chakra-ui/react'
import { useComposable } from 'composable/components/composable-provider'
import { UserAvatar } from 'composable/components/user-avatar'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoPersonCircleOutline } from 'react-icons/io5'
import { useAccountCT } from 'frontastic/contexts'
import { useCheckedLoggedIn } from 'composable/components/account/hooks/useCheckLoggedIn'

export const ProfileButton = () => {
  const { formatMessage } = useFormat()
  const { accountDrawer } = useComposable()
  const { account: userSessionData } = useAccountCT()
  const onOpenAccountDrawer = () => {
    accountDrawer.onOpen()
  }
  const { isLoggedIn, isLoggedOut } = useCheckedLoggedIn()

  return (
    <Skeleton isLoaded={isLoggedOut ? true : isLoggedIn} borderRadius={999}>
      <Button
        title={formatMessage({ id: 'header.account.title' })}
        variant="ghost"
        textDecoration="none"
        size="md"
        name="my account"
        aria-label={`Hi ${isLoggedIn ? `${userSessionData?.firstName}` : 'Guest'}`}
        width={'40px'}
        minW={'40px'}
        onClick={onOpenAccountDrawer}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <HStack gap={2}>
          {isLoggedIn ? (
            <UserAvatar name={`${userSessionData?.firstName} ${userSessionData?.lastName}`} />
          ) : (
            <IoPersonCircleOutline size="26px" color="#111111" />
          )}
        </HStack>
      </Button>
    </Skeleton>
  )
}
