import dynamic from 'next/dynamic'
import { LegacyHeaderSkeleton } from 'atg/components/legacy-header-r2'
import AdvancedOrderSearch from './advanced-order-search'
import FindmineClpPages from './findmine-clp'
import OrderDetails from './order-details'
import StaticBreadcrumb from './static-breadcrumb'

const AtgMegaMenuTastic = dynamic(() => import('./atg/mega-menu'))
const AtgLegacyHeaderTastic = dynamic(() => import('./atg/legacy-header/legacy-header-r2'), {
  loading: () => <LegacyHeaderSkeleton />,
})
const ArticleCardTastic = dynamic(() => import('./composable/article-card'))
const AccountDashboard = dynamic(() => import('./composable/account/account-dashboard-page'))
const AccountForgotPasswordTastic = dynamic(() => import('./composable/account/account-forgot-password-page'))
const AccountLoginTastic = dynamic(() => import('./composable/account/account-login-page'))
const AccountRegisterTastic = dynamic(() => import('./composable/account/account-register-page'))
const AccordionTastic = dynamic(() => import('./composable/accordion'))
const BannerFullTastic = dynamic(() => import('./composable/banner-full'))
const BannerSplitTastic = dynamic(() => import('./composable/banner-split'))
const BannerTextOnlyTastic = dynamic(() => import('./composable/banner-text-only'))
const PageHeaderTastic = dynamic(() => import('./composable/page-header'))
const CartTastic = dynamic(() => import('./composable/cart'))
const CheckoutTastic = dynamic(() => import('./composable/checkout'))
const CheckoutSuccessTastic = dynamic(() => import('./composable/checkout-success'))
const ContentstackEntryTastic = dynamic(() => import('./composable/contentstack-entry'))
const CoverCardTastic = dynamic(() => import('./composable/cover-card'))
const InstoreRedirectTastic = dynamic(() => import('./composable/instore-redirect'))
const FooterTastic = dynamic(() => import('./composable/footer'))
const DDCCheckoutFooterTastic = dynamic(() => import('./composable/checkout-footer'))
const HeaderDesktopTastic = dynamic(() => import('./composable/header-desktop'))
const NotFoundTastic = dynamic(() => import('./composable/not-found'))
const NotMatchedTastic = dynamic(() => import('./composable/not-matched'))
const PLPTastic = dynamic(() => import('./composable/plp'))
const ComposableProductDetailPage = dynamic(() => import('./composable/product-detail-page'))
const RichTextTastic = dynamic(() => import('./composable/rich-text'))
const ComposableSimilarProducts = dynamic(() => import('./composable/similar-products'))
const TextCardTastic = dynamic(() => import('./composable/text-card'))
const BackToTopTastic = dynamic(() => import('./composable/back-to-top'))
const DDCFooterCopyriteTastic = dynamic(() => import('./composable/ddc-footer-copywrite'))
const NewsLetterEmailSignupTastic = dynamic(() => import('./composable/newsletter-email-signup'))
const NoResultsTastic = dynamic(() => import('./composable/no-results'))
const ReviewFeedbackTastic = dynamic(() => import('./composable/review-feedback'))
const LoginTastic = dynamic(() => import('./composable/login'))
const InternationalChekoutTastic = dynamic(() => import('./composable/international-checkout'))

export const tastics = {
  'atg/legacy-header': AtgLegacyHeaderTastic,
  'atg/mega-menu': AtgMegaMenuTastic,
  'composable/article-card': ArticleCardTastic,
  'composable/banner-full': BannerFullTastic,
  'composable/accordion': AccordionTastic,
  'composable/banner-split': BannerSplitTastic,
  'composable/cover-card': CoverCardTastic,
  'composable/text-card': TextCardTastic,
  'composable/banner-text-only': BannerTextOnlyTastic,
  'composable/page-header': PageHeaderTastic,
  'composable/rich-text': RichTextTastic,
  'composable/header-desktop': HeaderDesktopTastic,
  'composable/footer': FooterTastic,
  'composable/DDCCheckoutFooter': DDCCheckoutFooterTastic,
  'composable/DDCfooterCopyright': DDCFooterCopyriteTastic,
  'composable/plp': PLPTastic,
  'composable/cart': CartTastic,
  'composable/instore-redirect': InstoreRedirectTastic,
  'composable/checkout': CheckoutTastic,
  'composable/checkout-success': CheckoutSuccessTastic,
  'composable/product-detail-page': ComposableProductDetailPage,
  'composable/similar-products': ComposableSimilarProducts,
  'composable/account/dashboard': AccountDashboard,
  'composable/account/login': AccountLoginTastic,
  'composable/account/register': AccountRegisterTastic,
  'composable/account/reset-password': AccountForgotPasswordTastic,
  'composable/back-to-top': BackToTopTastic,
  'composable/not-found': NotFoundTastic,
  'composable/login': LoginTastic,
  'composable/international-checkout': InternationalChekoutTastic,
  'contentstack/article-card': ContentstackEntryTastic,
  'contentstack/brands-list': ContentstackEntryTastic,
  'contentstack/cover-card': ContentstackEntryTastic,
  'contentstack/banner-full': ContentstackEntryTastic,
  'contentstack/banner-split': ContentstackEntryTastic,
  'contentstack/banner-text-only': ContentstackEntryTastic,
  'contentstack/grid': ContentstackEntryTastic,
  'contentstack/text-card': ContentstackEntryTastic,
  'contentstack/rich-text': ContentstackEntryTastic,
  'contentstack/rich-text-navigation-list': ContentstackEntryTastic,
  'contentstack/banner-promotion': ContentstackEntryTastic,
  'contentstack/team-logo-grid': ContentstackEntryTastic,
  'contentstack/page-container': ContentstackEntryTastic,
  'contentstack/entry': ContentstackEntryTastic,
  'contentstack/article-card-slider': ContentstackEntryTastic,
  'commercetools/ui/advanced-order-search': AdvancedOrderSearch,
  'commercetools/ui/order-details': OrderDetails,
  'contentstack/certona_slider': ContentstackEntryTastic,
  'commercetools/ui/findmine-clp-pages': FindmineClpPages,
  'commercetools/ui/static-breadcrumb': StaticBreadcrumb,
  'composable/newsletter-email-signup': NewsLetterEmailSignupTastic,
  'contentstack/banner-slider': ContentstackEntryTastic,
  'contentstack/video': ContentstackEntryTastic,
  'contentstack/accordion': ContentstackEntryTastic,
  'contentstack/footer-banner': ContentstackEntryTastic,
  'contentstack/banner-announcement': ContentstackEntryTastic,
  'contentstack/no-results': NoResultsTastic,
  'contentstack/review-feedback': ReviewFeedbackTastic,
  'contentstack/product-categories-slider': ContentstackEntryTastic,
  'contentstack/mega-menu': ContentstackEntryTastic,
  'contentstack/component_certona_slider': ContentstackEntryTastic,

  default: NotMatchedTastic,
}
