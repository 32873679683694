import { Box, Text } from '@chakra-ui/react'
import { IoPersonCircle } from 'react-icons/io5'

const getInitials = (name: string) => {
  const namesArray = name.split(' ')
  const initials = namesArray
    .map((namePart: string) => {
      if (!namePart || namePart === 'undefined') return ''
      return namePart.charAt(0)
    })
    .join('')
  return initials.toUpperCase()
}

export const UserAvatar = ({ name }) => {
  const initials = getInitials(name)
  return initials ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="24px"
      height="24px"
      bg="primary"
      color="white"
      borderRadius="full"
      gap="10px"
    >
      <Text fontSize="12px" fontWeight={600}>
        {initials}
      </Text>
    </Box>
  ) : (
    <IoPersonCircle size="26px" color="#111111" />
  )
}
