import Image, { ImageProps } from 'next/image'

export const BrandLogo = ({ title, style, priority = true, alt, ...props }: ImageProps) => {
  return (
    <Image
      title={title}
      priority={priority}
      alt={alt}
      {...props}
      aria-label={title ? title : alt}
      style={{
        ...style,
        maxWidth: '100%',
        height: 'auto',
      }}
    />
  )
}
