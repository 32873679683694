import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  Link,
  Stack,
  Text,
  useBreakpointValue,
  extendTheme,
  ChakraProvider,
  TextProps,
  Tag,
  Image,
} from '@chakra-ui/react'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { getOrderDetails } from 'frontastic/actions/advancedOrderSearch'
import { OrderStatusMap } from '.'

const OrderItems = (props: any) => {
  const [orderStatus, setOrderStatus] = useState<any>()
  const [defaultIndexVal, setDefaultIndexVal] = useState<number | number[]>([])
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [isExpanded, setIsExpanded] = useState(false)
  const router = useRouter()
  const { orderId } = router.query
  const item = props.item

  const { FirstName, LastName, Address1, Address2, Address3, City, State, PostalCode } = item?.deliveryAddress || {}
  let deliveryAddress = ''
  if (item?.deliveryType === 'PickUpAtStore') {
    const fullName = [FirstName, LastName].filter(Boolean).join(' ')
    deliveryAddress = [fullName, Address1, Address2, Address3].filter(Boolean).join(', ')
  } else {
    deliveryAddress = [Address1, Address2, Address3, City, State, PostalCode].filter(Boolean).join(', ')
  }

  const getDateformat = (timestamp: any) => {
    const date = new Date(timestamp)
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
    return formattedDate || '-'
  }

  useEffect(() => {
    if (orderId) {
      getDetails({ orderId: orderId })
    }
  }, [])

  useEffect(() => {
    setDefaultIndexVal(isMobile ? [] : 0)
  }, [isMobile])

  const getDetails = async (payload: any) => {
    const response = await getOrderDetails(payload)
    setOrderStatus(response)
  }

  const customTheme = extendTheme({
    components: {
      Accordion: {
        variants: {
          accordion: {
            button: {
              _hover: {
                background: 'transparent',
              },
              svg: {
                width: '28px',
                height: '28px',
                fontSize: '100%',
              },
            },
          },
        },
      },
    },
  })
  const handleToggle = () => {
    setIsExpanded(!isExpanded)
  }

  const customizationData = useMemo(() => {
    if (item.customizationData?.customization?.length > 0 && item.customizationData?.chargeValue) {
      return `${item.customizationData?.customization.join(', ')} - ${CurrencyHelpers.formatForCurrency(
        item.customizationData?.chargeValue,
      )}`
    }
  }, [item.customizationData])

  return (
    <>
      <Stack display="flex" alignItems="flex-start" gap="var(--chakra-space-3, 12px)" alignSelf="stretch" width="100%">
        <ChakraProvider cssVarsRoot="" theme={customTheme}>
          <Accordion
            allowToggle
            index={defaultIndexVal}
            onChange={(index) => {
              setDefaultIndexVal(index)
              handleToggle()
            }}
            width={'100%'}
            background="var(--semantic-surface-muted, #F3F4F6)"
            variant="accordion"
            px={{ base: 4, md: 5 }}
            py={{ base: 4, md: 6 }}
          >
            <AccordionItem borderBlockStart={0} borderBlockEnd={0}>
              <Box display={{ base: 'block', md: 'none' }}>
                <AccordionButton
                  padding={'0'}
                  paddingStart={0}
                  justifyContent="space-between"
                  alignItems={'flex-start'}
                >
                  <Box
                    flex={1}
                    fontSize={'sm'}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap={'var(--chakra-space-4, 16px)'}
                    display={'flex'}
                    flexDirection={'column'}
                  >
                    <OrderInfoItemTitle label={isExpanded ? 'Shipping Information' : 'Status'} />
                    <Box mb={isExpanded ? 4 : 0}>
                      <OrderStatusMap orderStatusValue={item?.status} />
                    </Box>
                  </Box>

                  <AccordionIcon />
                </AccordionButton>
              </Box>
              <AccordionPanel padding={'0'} background="var(--semantic-surface-muted, #F3F4F6)">
                <Grid
                  templateColumns={isMobile ? '1fr' : '1fr 1fr 1fr 1fr'}
                  display="flex"
                  justifyContent={'space-between'}
                  flexDirection={{ base: 'column', md: 'row' }}
                  alignItems="flex-start"
                  gap={4}
                  align-self="stretch"
                  border-top="1px solid var(--semantic-surface-border, #E7EAED)"
                  border-bottom="1px solid var(--semantic-surface-border, #E7EAED)"
                  background="var(--semantic-surface-muted, #F3F4F6)"
                  width="100%"
                >
                  <Box display={{ base: 'none', md: 'flex' }} flex={1}>
                    <OrderInfoItemMain>
                      <>
                        <OrderInfoItemTitle label={'Status'} />
                        <Box>
                          <OrderStatusMap orderStatusValue={item?.status} />
                        </Box>
                      </>
                    </OrderInfoItemMain>
                  </Box>
                  <OrderInfoItemMain>
                    <>
                      <OrderInfoItemTitle label={'Deliver By'}></OrderInfoItemTitle>
                      {item.deliveryType == 'ShipToAddress' && item?.estimateDeliveryDate?.lenth ? (
                        <OrderInfoItemContent label={item?.estimateDeliveryDate} />
                      ) : (
                        <OrderInfoItemContent label={'N/A'} />
                      )}
                    </>
                  </OrderInfoItemMain>
                  <OrderInfoItemMain>
                    <>
                      <OrderInfoItemTitle label={'Tracking ID:'}></OrderInfoItemTitle>
                      {item.trackingInfo?.url ? (
                        <Link
                          fontSize={'14px'}
                          fontWeight={400}
                          color={'var(--semantic-text-primary, #15191C)'}
                          textDecoration={'underline'}
                          href={item.trackingInfo.url}
                          isExternal
                        >
                          <OrderInfoItemContent label={item.trackingInfo.trackingNo} />
                        </Link>
                      ) : (
                        <OrderInfoItemContent label={'N/A'} />
                      )}
                    </>
                  </OrderInfoItemMain>
                  <OrderInfoItemMain>
                    <>
                      <OrderInfoItemTitle
                        label={item?.deliveryType !== 'PickUpAtStore' ? 'Shipped To' : 'Picked up at'}
                      ></OrderInfoItemTitle>
                      <OrderInfoItemContent label={`${deliveryAddress}`} />
                    </>
                  </OrderInfoItemMain>
                </Grid>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ChakraProvider>

        <Flex
          paddingBottom="var(--chakra-space-6, 24px)"
          alignItems="flex-start"
          gap="var(--chakra-space-4, 16px)"
          alignSelf="stretch"
          borderBottom={'1px solid var(--semantic-surface-border, #E7EAED)'}
        >
          <Box flexDirection="column" alignItems="flex-start">
            <Image width={{ base: '80px', md: '145px' }} src={item?.imgUrl} alt="" />
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            flex="1 0 0"
            padding={{ base: '0 16px 0 0', md: '16px 16px 0 0' }}
            gap={'var(--sizes-3, 12px)'}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Box
              display="flex"
              width={isMobile ? 'auto' : '304px'}
              height="auto"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Text color="var(--semantic-text-muted,#76797E)" fontSize="12px" fontWeight={400}>
                {item?.brandName}
              </Text>
              <Text fontSize="16px" color="var(--semantic-text-primary, #15191C)">
                {item?.description}
              </Text>
              <Text
                display={{ base: 'block', md: 'none' }}
                fontSize="14px"
                textStyle={item?.totalDiscounts ? 'figma-strikethrough-75' : 'body-75'}
              >
                {CurrencyHelpers.formatForCurrency(item?.unitPrice * 100)}
              </Text>
              {!!item?.totalDiscounts && (
                <Text display={{ base: 'block', md: 'none' }} fontSize="14px" textStyle={'body-75'} color="danger.600">
                  {CurrencyHelpers.formatForCurrency(item?.maxAppeasementAmount * 100)}
                </Text>
              )}
              <Text display={{ base: 'block', md: 'none' }} fontSize="12px" color="var(--semantic-text-muted,#76797E)">
                Quantity: {item?.quantity}
              </Text>
              <Text fontSize="12px" color="var(--semantic-text-muted,#76797E)">
                Item #: {item?.itemId}
              </Text>
              <Text fontSize="12px" color="var(--semantic-text-muted,#76797E)">
                Color: {item?.selectedColor}
                {item?.selectedSizes ? ', Size :' : ''} {item?.selectedSizes}
              </Text>
              {!!customizationData && (
                <Text fontSize="12px" color="var(--semantic-text-muted,#76797E)">
                  {customizationData}
                </Text>
              )}
              {props?.orderSummary?.discount !== 0 && !!item?.totalDiscounts && (
                <Text fontSize="12px" color="var(--semantic-text-muted,#76797E)">
                  Promo Applied: {CurrencyHelpers.formatForCurrency(item?.totalDiscounts * 100)}
                </Text>
              )}
            </Box>
            <Box display={{ base: 'none', md: 'flex' }} flexDirection={{ base: 'column', md: 'row' }} gap="8">
              <Box>
                <Text color={'text-muted'} fontSize="14px" fontWeight={'semibold'}>
                  Quantity
                </Text>
                <Text textAlign={'center'} fontSize="14px">
                  {item?.quantity}
                </Text>
              </Box>
              <Box>
                <Text color="var(--semantic-text-muted,#76797E)" fontSize="14px" fontWeight={'semibold'}>
                  Item Price
                </Text>
                <Text
                  textAlign={'right'}
                  fontSize="14px"
                  textStyle={item?.totalDiscounts ? 'figma-strikethrough-75' : 'body-75'}
                >
                  {CurrencyHelpers.formatForCurrency(item?.unitPrice * 100)}
                </Text>
                {!!item?.totalDiscounts && (
                  <Text textAlign={'right'} fontSize="14px" textStyle={'body-75'} color="danger.600">
                    {CurrencyHelpers.formatForCurrency(item?.maxAppeasementAmount * 100)}
                  </Text>
                )}
              </Box>
            </Box>
          </Box>
        </Flex>
      </Stack>
    </>
  )
}

export default OrderItems

interface OrderInfoItemTitleProps {
  label: string
  textProps?: TextProps
}

export const OrderInfoItemTitle = ({ label, ...textProps }: OrderInfoItemTitleProps) => {
  return (
    <Text
      color="var(--semantic-text-primary, #15191C)"
      fontWeight={600}
      fontSize={'16px'}
      lineHeight={'24px'}
      {...textProps}
    >
      {label}
    </Text>
  )
}

interface OrderInfoItemContentProps {
  label: string
  textProps?: TextProps
}

export const OrderInfoItemContent = ({ label, ...textProps }: OrderInfoItemContentProps) => {
  return (
    <Text fontSize={'sm'} color={'var(--semantic-text-primary, #15191C)'} {...textProps}>
      {label}
    </Text>
  )
}
interface OrderInfoItemMain {
  children: JSX.Element
  textProps?: TextProps
}

export const OrderInfoItemMain = ({ children, ...textProps }: OrderInfoItemMain) => {
  return (
    <Box gap={'var(--sizes-2, 8px)'} display={'flex'} flex={1} flexDirection={'column'} {...textProps}>
      {children}
    </Box>
  )
}
